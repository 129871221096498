<template>
    <about-subpage :charName="'episodes'">
        <template v-slot:content>
            <p class="mb-2">Character designs for</p>
            <h1 class="title">Episode 0</h1>
            <img @click="open" class="open" src="@/assets/img/episode-0/costumes.jpg" />
            <img @click="open" class="open" src="@/assets/img/episode-0/Planet-Puin.jpg" />
            <h2 class="mt-5 subtitle">Thumbnails</h2>
            <img @click="open" class="open" src="@/assets/img/episode-0/ep0-thumbs1.jpg" />
            <img @click="open" class="open" src="@/assets/img/episode-0/ep0-thumbs2.jpg" />
            <img @click="open" class="open" src="@/assets/img/episode-0/ep0-thumbs3.jpg" />
        </template>
    </about-subpage>
</template>

<script>
import AboutSubpage from "@/components/AboutSubpage.vue";

export default {
    name: "Episode0",
    components: { AboutSubpage },
    methods: {
        open(event) {
            window.open(event.target.src, '_blank')
        },
    }
};
</script>
